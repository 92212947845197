const loadSuperior = (id) => {
  const existingScript = document.getElementById('superiorTag');
  const host = (typeof window !== 'undefined') ? window.location.hostname : '';
  if (!existingScript && host.indexOf('localhost') === -1) {
    const script = document.createElement('script');
    script.src = `https://reetahoo.com/401/${id}`;
    script.id = 'superiorTag';
    script.async = 'async';
    script.dataset.cfasync = 'false';
    document.body.appendChild(script);
  }
};

const checkIsLoad = () => {
  function checkLoadByStyle(element) {
    var styles = element.style.cssText;
    return styles.includes('width: 100% !important') &&
           styles.includes('height: 100% !important') &&
           styles.includes('opacity: 1 !important');
  }
  
  var iframes = document.getElementsByTagName('iframe');

  return Array.from(iframes).find(iframe => checkLoadByStyle(iframe));

};

export default loadSuperior;
export {checkIsLoad};